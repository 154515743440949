<template>
  <div id="app">
    <googleMap/>
    <flashMessage/>
  </div>
</template>

<script>
import googleMap from './components/Map.vue'
import flashMessage from '@/components/FlashMessage.vue';

export default {
  name: 'App',
  components: {
    googleMap,
    flashMessage
  }
}
</script>




<style lang="less">

@import (css) url('https://fonts.googleapis.com/css?family=Nunito:400,700');

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;

}

#app,#map {
  width: 100vw;
  height: 100vh;
}



.loader,
.loader:after {
  border-radius: 50%;
  width: 12px;
  height: 12px;
}
.loader {
  display: inline-block;
  margin: 0px;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 2px solid rgba(255, 255, 255, 0.2);
  border-right: 2px solid rgba(255, 255, 255, 0.2);
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  border-left: 2px solid #fff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
