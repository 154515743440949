import polgons from "@/mixins/polygons";

export default {
    name: 'Maria',
    styles: {
        strokeColor: "#FF99FF",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF99FF",
        fillOpacity: 0.35,
    },
    mixins: [
        polgons
    ],


    data() {
        return {
            areas: [
                {
                    id: 15418,
                    paths: [
                        {lat: 49.116729, lng: 20.432905},
                        {lat: 49.116447, lng: 20.433174},
                        {lat: 49.115978, lng: 20.433936},
                        {lat: 49.115815, lng: 20.433729},
                        {lat: 49.116273, lng: 20.433084},
                        {lat: 49.116661, lng: 20.432690},
                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=19&c=20.433311,49.116272&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/15418?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 15508,
                    paths: [
                        {lat: 49.113120, lng: 20.427772},
                        {lat: 49.113232, lng: 20.428076},
                        {lat: 49.113329, lng: 20.428520},
                        {lat: 49.113365, lng: 20.428753},
                        {lat: 49.113381, lng: 20.428999},
                        {lat: 49.113379, lng: 20.429149},
                        {lat: 49.113322, lng: 20.429618},
                        {lat: 49.113211, lng: 20.430018},
                        {lat: 49.113114, lng: 20.429950},
                        {lat: 49.113192, lng: 20.429724},
                        {lat: 49.113257, lng: 20.429405},
                        {lat: 49.113268, lng: 20.429024},
                        {lat: 49.113215, lng: 20.428709},
                        {lat: 49.113142, lng: 20.428409},
                        {lat: 49.113079, lng: 20.428201},
                        {lat: 49.112965, lng: 20.427904},
                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=19&c=20.429136,49.113345&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/15508?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 16615,
                    paths: [
                        {lat: 49.107037, lng: 20.468132},
                        {lat: 49.107074, lng: 20.468411},
                        {lat: 49.106162, lng: 20.468669},
                        {lat: 49.104905, lng: 20.468642},
                        {lat: 49.104921, lng: 20.468390},
                        {lat: 49.106131, lng: 20.468366},
                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=18&c=20.469012,49.106078&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/16615?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 17699,
                    paths: [
                        {lat: 49.125664, lng: 20.449687},
                        {lat: 49.125986, lng: 20.450489},
                        {lat: 49.125742, lng: 20.450695},
                        {lat: 49.125609, lng: 20.450255},
                        {lat: 49.125436, lng: 20.449889},
                        {lat: 49.125427, lng: 20.449857},
                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=17&c=20.467294,49.106258&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/17699?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 18317,
                    paths: [
                        {lat: 49.123075, lng: 20.468606},
                        {lat: 49.122975, lng: 20.468574},
                        {lat: 49.122716, lng: 20.468526},
                        {lat: 49.122231, lng: 20.468473},
                        {lat: 49.122235, lng: 20.468346},
                        {lat: 49.122836, lng: 20.468308},
                        {lat: 49.123069, lng: 20.468405},
                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=17&c=20.467294,49.106258&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/18317?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 19280,
                    paths: [
                        {lat: 49.136198, lng: 20.491868},
                        {lat: 49.136131, lng: 20.491938},
                        {lat: 49.136481, lng: 20.492827},
                        {lat: 49.136904, lng: 20.493807},
                        {lat: 49.136984, lng: 20.493776},
                        {lat: 49.136738, lng: 20.493217},
                        {lat: 49.136465, lng: 20.492554},
                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=17&c=20.467294,49.106258&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/19280?zoom=false',
                    ...this.$options.styles
                },

                {
                    id: 19302,
                    paths: [
                        {lat: 49.134469, lng: 20.488478},
                        {lat: 49.134214, lng: 20.488064},
                        {lat: 49.134038, lng: 20.487855},
                        {lat: 49.133776, lng: 20.487738},
                        {lat: 49.133724, lng: 20.487950},
                        {lat: 49.133951, lng: 20.488050},
                        {lat: 49.134120, lng: 20.488256},
                        {lat: 49.134389, lng: 20.488690},

                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=17&c=20.467294,49.106258&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/19302?zoom=false',
                    ...this.$options.styles
                },

                {
                    id: 20457,
                    paths: [
                        {lat: 49.133814, lng: 20.487261},
                        {lat: 49.133775, lng: 20.487406},
                        {lat: 49.133993, lng: 20.487504},
                        {lat: 49.134122, lng: 20.487584},
                        {lat: 49.134143, lng: 20.487549},
                        {lat: 49.134090, lng: 20.487415},

                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=17&c=20.467294,49.106258&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/20457?zoom=false',
                    ...this.$options.styles
                },

                {
                    id: 20934,
                    paths: [
                        {lat: 49.141873, lng: 20.486013},
                        {lat: 49.141718, lng: 20.486141},
                        {lat: 49.141714, lng: 20.486082},
                        {lat: 49.141638, lng: 20.485748},
                        {lat: 49.141383, lng: 20.485354},
                        {lat: 49.140993, lng: 20.485084},
                        {lat: 49.140577, lng: 20.484895},
                        {lat: 49.140176, lng: 20.484794},
                        {lat: 49.140202, lng: 20.484735},
                        {lat: 49.140261, lng: 20.484716},
                        {lat: 49.140311, lng: 20.484678},
                        {lat: 49.140686, lng: 20.484777},
                        {lat: 49.141099, lng: 20.484965},
                        {lat: 49.141488, lng: 20.485230},
                        {lat: 49.141567, lng: 20.485333},
                        {lat: 49.141746, lng: 20.485628},

                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=17&c=20.467294,49.106258&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/20934?zoom=false',
                    ...this.$options.styles
                },

            ]
        }
    },
    render: () => null,

}