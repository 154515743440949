export default {
    props: {
        map: Object,
        google: Object,
        infoPopUp: Object,
    },
    created() {

    },
    mounted: function () {



        for (const i in this.areas) {


            var area =  new this.google.maps.Polygon(this.areas[i]);


                area.addListener("click",  () => {
                    let content =
                        'Parcela: <strong>' +this.areas[i].id + '</strong>'
                        + ' ('+this.$options.name+')<br><br>'
                        + '<a target="_blank" href="'+ this.areas[i].zbgis+'"> Katastralna <strong>mapa</strong></a><br>'
                        + this.areas[i].info;


                    this.infoPopUp.setContent( content);
                    this.infoPopUp.setPosition( this.windowPosition(this.areas[i].paths));
                    this.infoPopUp.open(this.map);


                });

            area.addListener("mouseover",  function () {
                this.setOptions({
                    strokeOpacity: 0.9,
                    fillOpacity: 0.5,
                });
            });

            area.addListener("mouseout",  function () {
                this.setOptions({
                    strokeOpacity: 0.8,
                    fillOpacity: 0.35,
                });

            });


            area.setMap(this.map);

        }

    },
    methods: {
        windowPosition: function (paths) {

            return paths.reduce(({ lat, lng }, li, index, array) => {
                if (array.length - 1 === index) {
                    return {
                        lat: (lat + li.lat) / array.length,
                        lng: (lng + li.lng) / array.length
                    };
                }

                return { lat: lat + li.lat, lng: lng + li.lng };
            });

        }
    }

}