<template>
  <section :data-section="this.$options.name">
    <transition name="fade">
      <div class="notification" v-bind:class="type" v-if="visible" v-on:click="geoLocation">

        <button class="delete" @click="hide"></button>
        <svg data-v-29ce9aa7="" width="25px" height="25px" viewBox="0 0 20 34.892337">
          <g data-v-29ce9aa7="" transform="translate(-814.59595,-274.38623)">
            <g data-v-29ce9aa7="" transform="matrix(1.1855854,0,0,1.1855854,-151.17715,-57.3976)">
              <path data-v-29ce9aa7=""
                    d="m 817.11249,282.97118 c -1.25816,1.34277 -2.04623,3.29881 -2.01563,5.13867 0.0639,3.84476 1.79693,5.3002 4.56836,10.59179 0.99832,2.32851 2.04027,4.79237 3.03125,8.87305 0.13772,0.60193 0.27203,1.16104 0.33416,1.20948 0.0621,0.0485 0.19644,-0.51262 0.33416,-1.11455 0.99098,-4.08068 2.03293,-6.54258 3.03125,-8.87109 2.77143,-5.29159 4.50444,-6.74704 4.56836,-10.5918 0.0306,-1.83986 -0.75942,-3.79785 -2.01758,-5.14062 -1.43724,-1.53389 -3.60504,-2.66908 -5.91619,-2.71655 -2.31115,-0.0475 -4.4809,1.08773 -5.91814,2.62162 z"
                    style="display: inline;fill-opacity: 0.5;fill: white;stroke: rgb(255 255 255);stroke-width: 2;stroke-miterlimit: 4;stroke-dasharray: none;stroke-opacity: 0.75;"></path>
              <circle data-v-29ce9aa7="" r="3.0355" cy="288.25278" cx="823.03064" id="path3049"
                      style="display: inline; opacity: 0.25; fill: rgb(0, 0, 0); fill-opacity: 1; stroke-width: 0;"></circle>
            </g>
          </g>
        </svg>
        <span v-html="message"></span>

      </div>
    </transition>
  </section>

</template>

<script>
export default {
  name: 'message',
  data() {
    return {
      visible: true,
      message: 'Zapnúť geolokáciu',
      type: 'info'
    }
  },
  created() {

    this.$root.$on('flash', data => {
      this.show(data)
    })
  },
  methods: {
    show(data) {
      if (data.timeout) {
        setTimeout(() => {
          if (this.type === 'warning') {
            this.show({
              message: `Zapnúť znova geolokáciu`,
              type: 'info'
            })
          }
        }, data.timeout)
      }


      this.message = data.message;
      this.type = data.type;


    },
    hide() {
      this.visible = false;
    },
    geoLocation() {
      this.$root.$emit('geoLocation');
    }
  }
}
</script>
<style scoped lang="less">

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

section {
  position: fixed;
  z-index: 100;
  padding: 1rem;
  left: 0;
  bottom: 0;

  svg {
    position: absolute;
    left: 0.5rem;
  }

  .notification {
    transition: background-color 0.5s;

    &.info {
      background-color: #3298dc;
      color: #fff;
    }

    &.success {
      background-color: #48c774;
      color: #fff;
    }

    &.warning {
      background-color: #d2ac17;
      color: #fff;
    }

    &.error {
      background-color: #bd1a3a;
      color: #fff;
    }

    background-color: #f5f5f5;
    border-radius: 4px;
    position: relative;
    padding: 1rem 1rem 1rem 2.5rem;
    width: calc(100% - 3rem);
    min-width: 175px;
    max-width: 400px;
    margin: 0px;


    button {
      background-color: rgba(10, 10, 10, .2);
      border: none;
      border-radius: 290486px;
      cursor: pointer;
      pointer-events: auto;
      display: none;
      flex-grow: 0;
      flex-shrink: 0;
      font-size: 0;
      height: 20px;
      max-height: 20px;
      max-width: 20px;
      min-height: 20px;
      min-width: 20px;
      outline: 0;
      vertical-align: top;
      width: 20px;

      right: .5rem;
      position: absolute;
      top: .5rem;

      &:after {
        background-color: #fff;
        content: "";
        display: block;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        transform-origin: center center;
        height: 2px;
        width: 50%;
      }

      &:before {
        background-color: #fff;
        content: "";
        display: block;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        transform-origin: center center;
        height: 50%;
        width: 2px;
      }
    }
  }
}



</style>
