import polgons from "@/mixins/polygons";

export default {
    name: 'Michal',
    styles: {
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
    },
    mixins: [
        polgons
    ],

    data() {
        return {
            areas: [
                {
                    id: 16021,
                    paths: [
                        {lat: 49.103351, lng: 20.445231},
                        {lat: 49.103333, lng: 20.445671},
                        {lat: 49.102700, lng: 20.445606},
                        {lat: 49.102102, lng: 20.445537},
                        {lat: 49.102124, lng: 20.445093},
                        {lat: 49.103351, lng: 20.445231},
                    ],
                    info: "",
                    zbgis:'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=18&c=20.444813,49.102783&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/16021?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 17314,
                    paths: [
                        {lat: 49.129583, lng: 20.440185},
                        {lat: 49.129594, lng: 20.440264},
                        {lat: 49.129629, lng: 20.440356},
                        {lat: 49.128778, lng: 20.440682},
                        {lat: 49.128748, lng: 20.440507},
                        {lat: 49.129583, lng: 20.440185},
                    ],
                    info: "",
                    zbgis:'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=18&c=20.440171,49.129188&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/17314?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 17700,
                    paths: [
                        {lat: 49.126263, lng: 20.451188},
                        {lat: 49.125967, lng: 20.451409},
                        {lat: 49.125742, lng: 20.450694},
                        {lat: 49.125983, lng: 20.450493},
                        {lat: 49.126263, lng: 20.451188},
                    ],
                    info: "",
                    zbgis:'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=18&c=20.450686,49.126002&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/17700?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 17844,
                    paths: [
                        {lat: 49.129064, lng: 20.462782},
                        {lat: 49.128832, lng: 20.462957},
                        {lat: 49.128802, lng: 20.462676},
                        {lat: 49.129006, lng: 20.462530},
                        {lat: 49.129013, lng: 20.462598},
                        {lat: 49.129031, lng: 20.462685},
                        {lat: 49.129064, lng: 20.462782},
                    ],
                    info: "",
                    zbgis:'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=18&c=20.462607,49.128932&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/17844?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 18281,
                    paths: [
                        {lat: 49.119452, lng: 20.463617},
                        {lat: 49.119200, lng: 20.464763},
                        {lat: 49.119192, lng: 20.465211},
                        {lat: 49.119209, lng: 20.465307},
                        {lat: 49.119168, lng: 20.465334},
                        {lat: 49.119142, lng: 20.465216},
                        {lat: 49.119133, lng: 20.464800},
                        {lat: 49.119147, lng: 20.464506},
                        {lat: 49.119353, lng: 20.463571},
                    ],
                    info: "",
                    zbgis:'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=18&c=20.463932,49.119296&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/18281?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 20405,
                    paths: [
                        {lat: 49.137277, lng: 20.477114},
                        {lat: 49.137013, lng: 20.477252},
                        {lat: 49.136831, lng: 20.476889},
                        {lat: 49.137013, lng: 20.476575},
                        {lat: 49.137139, lng: 20.476756},
                        {lat: 49.137183, lng: 20.476775},
                        {lat: 49.137277, lng: 20.477114},
                    ],
                    info: "",
                    zbgis:'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=18&c=20.476646,49.137048&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/20405?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 20936,
                    paths: [
                        {lat: 49.141871, lng: 20.486016},
                        {lat: 49.142111, lng: 20.485819},
                        {lat: 49.142060, lng: 20.485591},
                        {lat: 49.141904, lng: 20.485269},
                        {lat: 49.141658, lng: 20.484948},
                        {lat: 49.141382, lng: 20.484730},
                        {lat: 49.141277, lng: 20.485095},
                        {lat: 49.141486, lng: 20.485232},
                        {lat: 49.141744, lng: 20.485629},
                        {lat: 49.141871, lng: 20.486016},
                    ],
                    info: "",
                    zbgis:'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=18&c=20.476646,49.137048&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/20936?zoom=false',
                    ...this.$options.styles
                },
            ]
        }
    },
    render: () => null,

}