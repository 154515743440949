import polgons from "@/mixins/polygons";

export default {
    name: 'Mia',
    styles: {
        strokeColor: "#FFFFFF",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FFFFFF",
        fillOpacity: 0.35,
    },
    mixins: [
        polgons
    ],

    data() {
        return {
            areas: [
                {
                    id: 15328,
                    paths: [
                        {lat: 49.120837, lng: 20.430480},
                        {lat: 49.120629, lng: 20.430339},
                        {lat: 49.120590, lng: 20.430464},
                        {lat: 49.120534, lng: 20.430575},
                        {lat: 49.120269, lng: 20.430837},
                        {lat: 49.119960, lng: 20.431059},
                        {lat: 49.119730, lng: 20.431336},
                        {lat: 49.119626, lng: 20.431494},
                        {lat: 49.119695, lng: 20.431619},
                        {lat: 49.119969, lng: 20.431271},
                        {lat: 49.120264, lng: 20.431031},
                        {lat: 49.120661, lng: 20.430815},
                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=19&c=20.487405,49.133958&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/15328?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 16613,
                    paths: [
                        {lat: 49.107008, lng: 20.467585},
                        {lat: 49.106987, lng: 20.467730},
                        {lat: 49.107005, lng: 20.467888},
                        {lat: 49.106124, lng: 20.468135},
                        {lat: 49.106109, lng: 20.467857},
                        {lat: 49.106112, lng: 20.467832},
                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=19&c=20.487405,49.133958&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/16613?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 17313,
                    paths: [

                        {lat: 49.129702, lng: 20.440552},
                        {lat: 49.128815, lng: 20.440894},
                        {lat: 49.128779, lng: 20.440682},
                        {lat: 49.129632, lng: 20.440355},
                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=19&c=20.487405,49.133958&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/17313?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 18313,
                    paths: [
                        {lat: 49.122189, lng: 20.468355},
                        {lat: 49.122197, lng: 20.468573},
                        {lat: 49.121142, lng: 20.468489},
                        {lat: 49.120470, lng: 20.468345},
                        {lat: 49.120501, lng: 20.468202},
                        {lat: 49.121398, lng: 20.468296},
                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=19&c=20.487405,49.133958&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/21532?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 18455,
                    paths: [
                        {lat: 49.116745, lng: 20.481560},
                        {lat: 49.116731, lng: 20.481677},
                        {lat: 49.116665, lng: 20.482586},
                        {lat: 49.116466, lng: 20.482542},
                        {lat: 49.116532, lng: 20.481627},
                        {lat: 49.116544, lng: 20.481513},
                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=19&c=20.487405,49.133958&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/18455?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 21532,
                    paths: [
                        {lat: 49.170642, lng: 20.514385},
                        {lat: 49.170712, lng: 20.514298},
                        {lat: 49.170453, lng: 20.514038},
                        {lat: 49.170009, lng: 20.514357},
                        {lat: 49.169475, lng: 20.514750},
                        {lat: 49.169529, lng: 20.515044},
                        {lat: 49.170529, lng: 20.514235},
                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=19&c=20.487405,49.133958&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/21532?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 18595,
                    paths: [
                        {lat: 49.123356, lng: 20.481995},
                        {lat: 49.123190, lng: 20.481897},
                        {lat: 49.123073, lng: 20.482318},
                        {lat: 49.122955, lng: 20.482692},
                        {lat: 49.122909, lng: 20.482784},
                        {lat: 49.123016, lng: 20.482911},
                        {lat: 49.123234, lng: 20.482482},
                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=19&c=20.482517,49.123147&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/18595?zoom=false',
                    ...this.$options.styles
                },

            ]
        }
    },
    render: () => null,

}