import polgons from "@/mixins/polygons";

export default {
    name: 'Marek',
    styles: {
        strokeColor: "#F88D00",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#F88D00",
        fillOpacity: 0.35,
    },
    mixins: [
        polgons
    ],

    data() {
        return {
            areas: [
                {
                    id: 16022,
                    paths: [
                        {lat: 49.102124, lng: 20.445092},
                        {lat: 49.102103, lng: 20.445533},
                        {lat: 49.101228, lng: 20.445434},
                        {lat: 49.101235, lng: 20.444994},
                        {lat: 49.102124, lng: 20.445092},
                    ],
                    info: "",
                    zbgis:'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=18&c=20.444291,49.101723&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/16022?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 17316,
                    paths: [
                        {lat: 49.128817, lng: 20.440896},
                        {lat: 49.128358, lng: 20.441073},
                        {lat: 49.128202, lng: 20.440559},
                        {lat: 49.128728, lng: 20.440358},
                        {lat: 49.128817, lng: 20.440896},
                    ],
                    info: "",
                    zbgis:'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=18&c=20.440238,49.128201&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/17316?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 17701,
                    paths: [
                        {lat: 49.126565, lng: 20.451931},
                        {lat: 49.126245, lng: 20.452203},
                        {lat: 49.126166, lng: 20.452080},
                        {lat: 49.125968, lng: 20.451407},
                        {lat: 49.126265, lng: 20.451187},
                        {lat: 49.126565, lng: 20.451931},
                    ],
                    info: "",
                    zbgis:'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=18&c=20.451177,49.126198&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/17701?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 18456,
                    paths: [
                        {lat: 49.116798, lng: 20.481007},
                        {lat: 49.116787, lng: 20.481152},
                        {lat: 49.116743, lng: 20.481558},
                        {lat: 49.116543, lng: 20.481513},
                        {lat: 49.116603, lng: 20.480963},
                        {lat: 49.116798, lng: 20.481007},
                    ],
                    info: "",
                    zbgis:'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=18&c=20.481006,49.116469&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/18456?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 18589,
                    paths: [
                        {lat: 49.122389, lng: 20.483736},
                        {lat: 49.122001, lng: 20.484084},
                        {lat: 49.121913, lng: 20.484004},
                        {lat: 49.121870, lng: 20.483925},
                        {lat: 49.121791, lng: 20.483832},
                        {lat: 49.121698, lng: 20.483764},
                        {lat: 49.121962, lng: 20.483687},
                        {lat: 49.122221, lng: 20.483562},
                        {lat: 49.122278, lng: 20.483519},
                        {lat: 49.122389, lng: 20.483736},
                    ],
                    info: "",
                    zbgis:'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=18&c=20.483548,49.122041&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/18589?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 19300,
                    paths: [
                        {lat: 49.134501, lng: 20.488279},
                        {lat: 49.134502, lng: 20.488395},
                        {lat: 49.134469, lng: 20.488479},
                        {lat: 49.134214, lng: 20.488064},
                        {lat: 49.134041, lng: 20.487857},
                        {lat: 49.133952, lng: 20.487819},
                        {lat: 49.134015, lng: 20.487576},
                        {lat: 49.134091, lng: 20.487621},
                        {lat: 49.134198, lng: 20.487708},
                        {lat: 49.134288, lng: 20.487829},
                        {lat: 49.134425, lng: 20.488081},
                        {lat: 49.134501, lng: 20.488279},
                    ],
                    info: "",
                    zbgis:'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=18&c=20.487474,49.134331&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/19300?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 20935,
                    paths: [
                        {lat: 49.141386, lng: 20.484725},
                        {lat: 49.141279, lng: 20.485087},
                        {lat: 49.141091, lng: 20.484962},
                        {lat: 49.140688, lng: 20.484779},
                        {lat: 49.140309, lng: 20.484675},
                        {lat: 49.140705, lng: 20.484367},
                        {lat: 49.140911, lng: 20.484459},
                        {lat: 49.141386, lng: 20.484725},
                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=18&c=20.484732,49.140846&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/20935?zoom=false',
                    ...this.$options.styles
                },
            ]
        }
    },
    render: () => null,

}