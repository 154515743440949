<template>
  <div>

    <section id="map">
      <michal v-if="this.map && this.google" :map="map" :google="google" :infoPopUp="infoWindow"/>
      <marek v-if="this.map && this.google" :map="map" :google="google" :infoPopUp="infoWindow"/>
      <janko v-if="this.map && this.google" :map="map" :google="google" :infoPopUp="infoWindow"/>
      <lenka v-if="this.map && this.google" :map="map" :google="google" :infoPopUp="infoWindow"/>
      <mia v-if="this.map && this.google" :map="map" :google="google" :infoPopUp="infoWindow"/>
      <maria v-if="this.map && this.google" :map="map" :google="google" :infoPopUp="infoWindow"/>
      <kamama v-if="this.map && this.google" :map="map" :google="google" :infoPopUp="infoWindow"/>
      <geoLocation v-if="this.map && this.google" :map="map" :google="google"/>

    </section>

  </div>
</template>

<script>
import gmapsInit from '../utils/mapApi';
import michal from './Map/Michal'
import marek from './Map/Marek'
import janko from './Map/Janko'
import lenka from './Map/Lenka'
import mia from './Map/Mia'
import maria from './Map/Maria'
import kamama from './Map/Kamama'
import geoLocation from './Map/geoLocation'

export default {

  name: 'App',

  data() {
    return {
      map: null,
      google: null,
      infoWindow: null,
    }
  },
  components: {
    michal,
    marek,
    janko,
    lenka,
    mia,
    maria,
    kamama,
    geoLocation,
  },

  async mounted() {
    try {
      this.google = await gmapsInit();

      this.map =  new this.google.maps.Map(document.getElementById('map'),
          {
            zoom: 14,
            center: { lat: 49.1360184, lng: 20.4821455 },
            mapTypeId: "satellite",
          }
      );


      this.infoWindow = new this.google.maps.InfoWindow({
        content: 'xoxoxox',
        position: {lat: 49.116849, lng: 20.480128},
      });







    } catch (error) {
      this.$root.$emit('flash',
          {
            message:`Error: ${error} `,
            type:'error'
          }
      )
    }
  },

}
</script>




<style lang="less">
html,
body {
  margin: 0;
  padding: 0;
}

#app,#map {
  width: 100vw;
  height: 100vh;
}
</style>
