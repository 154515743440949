import polgons from "@/mixins/polygons";

export default {
    name: 'Janko',
    styles: {
        strokeColor: "#FFED00",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FFED00",
        fillOpacity: 0.35,
    },
    mixins: [
        polgons
    ],

    data() {
        return {
            areas: [
                {
                    id: 15465,
                    paths: [
                        {lat: 49.114402, lng: 20.433712},
                        {lat: 49.113914, lng: 20.433902},
                        {lat: 49.113416, lng: 20.434026},
                        {lat: 49.113447, lng: 20.433853},
                        {lat: 49.113846, lng: 20.433776},
                        {lat: 49.114342, lng: 20.433571},
                        {lat: 49.114402, lng: 20.433712},
                    ],
                    info: "",
                    zbgis:'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=19&c=20.433533,49.113903&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/15465?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 15507,
                    paths: [
                        {lat: 49.113481, lng: 20.429342},
                        {lat: 49.113435, lng: 20.429905},
                        {lat: 49.113435, lng: 20.430117},
                        {lat: 49.113324, lng: 20.430086},
                        {lat: 49.113212, lng: 20.430022},
                        {lat: 49.113327, lng: 20.429614},
                        {lat: 49.113362, lng: 20.429313},
                        {lat: 49.113481, lng: 20.429342},
                    ],
                    info: "",
                    zbgis:'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=19&c=20.429176,49.113256&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/15507?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 18282,
                    paths: [
                        {lat: 49.119358, lng: 20.463567},
                        {lat: 49.119149, lng: 20.464505},
                        {lat: 49.119136, lng: 20.464799},
                        {lat: 49.119143, lng: 20.465214},
                        {lat: 49.119170, lng: 20.465332},
                        {lat: 49.119100, lng: 20.465379},
                        {lat: 49.119007, lng: 20.464961},
                        {lat: 49.118982, lng: 20.464452},
                        {lat: 49.119215, lng: 20.463496},
                        {lat: 49.119358, lng: 20.463567},
                    ],
                    info: "",
                    zbgis:'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=19&c=20.463920,49.119182&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/18282?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 18314,
                    paths: [
                        {lat: 49.122205, lng: 20.468783},
                        {lat: 49.121127, lng: 20.468747},
                        {lat: 49.120427, lng: 20.468546},
                        {lat: 49.120471, lng: 20.468345},
                        {lat: 49.121133, lng: 20.468491},
                        {lat: 49.122196, lng: 20.468577},
                        {lat: 49.122205, lng: 20.468783},
                    ],
                    info: "",
                    zbgis:'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=18&c=20.468053,49.121309&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/18314?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 18591,
                    paths: [
                        {lat: 49.123018, lng: 20.482910},
                        {lat: 49.122829, lng: 20.483287},
                        {lat: 49.122638, lng: 20.483519},
                        {lat: 49.122387, lng: 20.483736},
                        {lat: 49.122279, lng: 20.483520},
                        {lat: 49.122473, lng: 20.483372},
                        {lat: 49.122613, lng: 20.483235},
                        {lat: 49.122749, lng: 20.483063},
                        {lat: 49.122908, lng: 20.482784},
                        {lat: 49.123018, lng: 20.482910},
                    ],
                    info: "",
                    zbgis:'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=19&c=20.482999,49.122647&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/18591?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 20480,
                    paths: [
                        {lat: 49.135999, lng: 20.484451},
                        {lat: 49.135960, lng: 20.484863},
                        {lat: 49.135997, lng: 20.485298},
                        {lat: 49.136090, lng: 20.485838},
                        {lat: 49.135888, lng: 20.485888},
                        {lat: 49.135855, lng: 20.485780},
                        {lat: 49.135760, lng: 20.485327},
                        {lat: 49.135731, lng: 20.484912},
                        {lat: 49.135712, lng: 20.484032},
                        {lat: 49.135750, lng: 20.483837},
                        {lat: 49.135791, lng: 20.483696},
                        {lat: 49.135898, lng: 20.483499},
                        {lat: 49.136151, lng: 20.483720},
                        {lat: 49.135997, lng: 20.484133},
                        {lat: 49.135943, lng: 20.484425},
                        {lat: 49.135999, lng: 20.484451},
                    ],
                    info: "",
                    zbgis:'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=18&c=20.484173,49.135952&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/20480?zoom=false',
                    ...this.$options.styles
                },

            ]
        }
    },
    render: () => null,

}