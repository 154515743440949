import polgons from "@/mixins/polygons";

export default {
    name: 'Kamama',
    styles: {
        strokeColor: "#36f64c",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#36f64c",
        fillOpacity: 0.35,
    },
    mixins: [
        polgons
    ],

    data() {
        return {
            areas: [
                {
                    id: 15329,
                    paths: [
                        {lat: 49.119628, lng: 20.431492},
                        {lat: 49.119696, lng: 20.431617},
                        {lat: 49.119374, lng: 20.432108},
                        {lat: 49.119293, lng: 20.432297},
                        {lat: 49.119244, lng: 20.432458},
                        {lat: 49.119108, lng: 20.432955},
                        {lat: 49.119061, lng: 20.433082},
                        {lat: 49.118820, lng: 20.433631},
                        {lat: 49.118787, lng: 20.433527},
                        {lat: 49.118879, lng: 20.433257},
                        {lat: 49.118938, lng: 20.432985},
                        {lat: 49.119119, lng: 20.432450},
                        {lat: 49.119208, lng: 20.432203},
                        {lat: 49.119286, lng: 20.432014},
                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=18&c=20.432550,49.119238&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/15329?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 15464,
                    paths: [
                        {lat: 49.114480, lng: 20.433886},
                        {lat: 49.114403, lng: 20.433712},
                        {lat: 49.113914, lng: 20.433900},
                        {lat: 49.113413, lng: 20.434029},
                        {lat: 49.113383, lng: 20.434199},
                        {lat: 49.113889, lng: 20.434069},

                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=19&c=20.433439,49.113925&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/15464?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 17180,
                    paths: [
                        {lat: 49.115835, lng: 20.479919},
                        {lat: 49.115826, lng: 20.480063},
                        {lat: 49.115074, lng: 20.479905},
                        {lat: 49.115088, lng: 20.479762},

                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=19&c=20.479983,49.115456&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/17180?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 17317,
                    paths: [
                        {lat: 49.129496, lng: 20.439554},
                        {lat: 49.129101, lng: 20.439735},
                        {lat: 49.128759, lng: 20.439790},
                        {lat: 49.128367, lng: 20.439894},
                        {lat: 49.128033, lng: 20.440014},
                        {lat: 49.128200, lng: 20.440562},
                        {lat: 49.128726, lng: 20.440358},
                        {lat: 49.129567, lng: 20.440033},

                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=19&c=20.439547,49.128796&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/17317?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 17665,
                    paths: [
                        {lat: 49.128434, lng: 20.446928},
                        {lat: 49.128316, lng: 20.447065},
                        {lat: 49.128128, lng: 20.446706},
                        {lat: 49.128188, lng: 20.446640},
                        {lat: 49.128064, lng: 20.446402},
                        {lat: 49.127915, lng: 20.446088},
                        {lat: 49.127845, lng: 20.445922},
                        {lat: 49.127725, lng: 20.445579},
                        {lat: 49.127505, lng: 20.445125},
                        {lat: 49.127545, lng: 20.445044},
                        {lat: 49.127733, lng: 20.445396},
                        {lat: 49.127815, lng: 20.445585},
                        {lat: 49.127888, lng: 20.445769},
                        {lat: 49.127959, lng: 20.445974},
                        {lat: 49.128083, lng: 20.446264},

                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=19&c=20.445789,49.127968&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/17665?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 18283,
                    paths: [
                        {lat: 49.119213, lng: 20.463495},
                        {lat: 49.119016, lng: 20.463399},
                        {lat: 49.118772, lng: 20.464417},
                        {lat: 49.118797, lng: 20.464918},
                        {lat: 49.118925, lng: 20.465492},
                        {lat: 49.119101, lng: 20.465378},
                        {lat: 49.119007, lng: 20.464959},
                        {lat: 49.118982, lng: 20.464452},

                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=19&c=20.464568,49.118925&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/18283?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 18457,
                    paths: [
                        {lat: 49.116849, lng: 20.480128},
                        {lat: 49.116490, lng: 20.480042},
                        {lat: 49.116460, lng: 20.480466},
                        {lat: 49.116443, lng: 20.480896},
                        {lat: 49.116604, lng: 20.480946},
                        {lat: 49.116600, lng: 20.480962},
                        {lat: 49.116801, lng: 20.481007},

                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=19&c=20.480368,49.116532&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/18457?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 20402,
                    paths: [
                        {lat: 49.137462, lng: 20.476895},
                        {lat: 49.137185, lng: 20.476774},
                        {lat: 49.137423, lng: 20.477641},
                        {lat: 49.137574, lng: 20.478652},
                        {lat: 49.137767, lng: 20.478582},
                        {lat: 49.137638, lng: 20.477668},

                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=19&c=20.477697,49.137235&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/20402?zoom=false',
                    ...this.$options.styles
                },
            ]
        }
    },
    render: () => null,

}