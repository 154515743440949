
export default {
    name: 'geoLocation',
    geoLocationActive: false,
    geoLocationEnabled: false,
    watchId: null,
    positionMarker: null,
    props: {
        map: Object,
        google: Object,
    },
    created() {
        this.$root.$on('geoLocation', () => {

            if(!this.geoLocationEnabled){
                this.activeLocation();
            }else{
                this.disableLocation();
            }
        })
    },
    mounted() {

    },
    methods: {
        activeLocation() {
            this.geoLocationEnabled = true;

            if(!this.positionMarker){
                this.positionMarker = new this.google.maps.Marker();
            }


            this.$root.$emit('flash',
                {
                    message:`Zapínam geolokáciu <div class="loader">spinner</div>`,
                    type:'warning',
                }
            )

            if (navigator.geolocation) {
                this.watchId = navigator.geolocation.watchPosition(
                    (position) => {
                        const pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };
                        this.positionMarker.setPosition(pos);
                        this.positionMarker.setMap(this.map);

                        if(!this.geoLocationActive){
                            this.geoLocationActive = true
                            this.map.setCenter(pos);
                            this.$root.$emit('flash',
                                {
                                    message:`Geolokácia zapnutá`,
                                    type:'success'
                                }
                            )
                        }
                    },
                    (err) => {
                        this.geoLocationActive = false
                        this.$root.$emit('flash',
                            {
                                message:`Chyba! (${err.code}):${err.message}`,
                                type:'error'
                            }
                        )
                    },
                    {
                        enableHighAccuracy: true,
                        timeout: 5000,
                        maximumAge: 0
                    }
                );
            }else{
                this.geoLocationEnabled = false;
                this.geoLocationActive = false;
                this.positionMarker.setMap(null);
                
                this.$root.$emit('flash',
                    {
                        message:'Prehliadač nepodporuje geolokáciu! ',
                        type:'error'
                    }
                )
            }
        },
        disableLocation() {
            this.geoLocationEnabled = false;
            this.geoLocationActive = false;
            this.positionMarker.setMap(null);

            navigator.geolocation.clearWatch( this.watchId );
            this.$root.$emit('flash',
                {
                    message:`Geolokácia vypnutá`,
                    type:'warning',
                    timeout: 3000
                }
            )
        }
    },
    render: () => null,

}