import polgons from "@/mixins/polygons";

export default {
    name: 'Lenka',
    styles: {
        strokeColor: "#00EDFF",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#00EDFF",
        fillOpacity: 0.35,
    },
    mixins: [
        polgons
    ],

    data() {
        return {
            areas: [
                {
                    id: 15465,
                    paths: [


                        {lat: 49.117693, lng: 20.431595},
                        {lat: 49.117521, lng: 20.431935},
                        {lat: 49.117314, lng: 20.432269},
                        {lat: 49.116990, lng: 20.432655},
                        {lat: 49.116728, lng: 20.432907},
                        {lat: 49.116658, lng: 20.432690},
                        {lat: 49.116851, lng: 20.432505},
                        {lat: 49.117055, lng: 20.432288},
                        {lat: 49.117292, lng: 20.431985},
                        {lat: 49.117617, lng: 20.431514},

                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=19&c=20.431799,49.117376&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/15417?zoom=false',
                    ...this.$options.styles
                },

                {
                    id: 15506,
                    paths: [

                        {lat: 49.113164, lng: 20.427644},
                        {lat: 49.113211, lng: 20.427694},
                        {lat: 49.113262, lng: 20.427781},
                        {lat: 49.113313, lng: 20.427966},
                        {lat: 49.113453, lng: 20.428677},
                        {lat: 49.113489, lng: 20.429041},
                        {lat: 49.113489, lng: 20.429246},
                        {lat: 49.113481, lng: 20.429345},
                        {lat: 49.113359, lng: 20.429314},
                        {lat: 49.113377, lng: 20.429144},
                        {lat: 49.113382, lng: 20.428992},
                        {lat: 49.113363, lng: 20.428751},
                        {lat: 49.113328, lng: 20.428520},
                        {lat: 49.113234, lng: 20.428077},
                        {lat: 49.113121, lng: 20.427774},
                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=18&c=20.428007,49.113238&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/15506?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 16663,
                    paths: [
                        {lat: 49.112301, lng: 20.461450},
                        {lat: 49.112558, lng: 20.462015},
                        {lat: 49.112937, lng: 20.462994},
                        {lat: 49.112916, lng: 20.463030},
                        {lat: 49.112866, lng: 20.463105},
                        {lat: 49.112794, lng: 20.463190},
                        {lat: 49.112404, lng: 20.462185},
                        {lat: 49.112146, lng: 20.461616},

                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=18&c=20.461525,49.112219&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/16663?zoom=false',
                    ...this.$options.styles
                },

                {
                    id: 17185,
                    paths: [
                        {lat: 49.114418, lng: 20.479407},
                        {lat: 49.114369, lng: 20.479553},
                        {lat: 49.115094, lng: 20.479706},
                        {lat: 49.115106, lng: 20.479553},


                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=19&c=20.479296,49.114736&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/17185?zoom=false',
                    ...this.$options.styles
                },


                {
                    id: 17315,
                    paths: [
                        {lat: 49.129565, lng: 20.440035},
                        {lat: 49.129585, lng: 20.440181},
                        {lat: 49.128749, lng: 20.440507},
                        {lat: 49.128726, lng: 20.440358},


                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=19&c=20.440009,49.129152&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/17315?zoom=false',
                    ...this.$options.styles
                },

                {
                    id: 17666,
                    paths: [
                        {lat: 49.127504, lng: 20.445125},
                        {lat: 49.127726, lng: 20.445577},
                        {lat: 49.127843, lng: 20.445923},
                        {lat: 49.127920, lng: 20.446096},
                        {lat: 49.128188, lng: 20.446641},
                        {lat: 49.128129, lng: 20.446706},
                        {lat: 49.128005, lng: 20.446478},
                        {lat: 49.127785, lng: 20.446009},
                        {lat: 49.127665, lng: 20.445649},
                        {lat: 49.127450, lng: 20.445219},

                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=19&c=20.445541,49.127841&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/17666?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 19301,
                    paths: [
                        {lat: 49.134014, lng: 20.487576},
                        {lat: 49.133953, lng: 20.487818},
                        {lat: 49.133774, lng: 20.487737},
                        {lat: 49.133832, lng: 20.487492},
                        {lat: 49.133985, lng: 20.487558},

                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=19&c=20.487405,49.133958&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/19301?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 20404,
                    paths: [
                        {lat: 49.137278, lng: 20.477113},
                        {lat: 49.137014, lng: 20.477247},
                        {lat: 49.137150, lng: 20.477516},
                        {lat: 49.137263, lng: 20.477809},
                        {lat: 49.137362, lng: 20.477883},
                        {lat: 49.137406, lng: 20.478285},
                        {lat: 49.137471, lng: 20.478688},
                        {lat: 49.137575, lng: 20.478650},
                        {lat: 49.137426, lng: 20.477646},

                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=19&c=20.487405,49.133958&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/20404?zoom=false',
                    ...this.$options.styles
                },
                {
                    id: 21533,
                    paths: [
                        {lat: 49.170770, lng: 20.514220},
                        {lat: 49.170641, lng: 20.514039},
                        {lat: 49.170633, lng: 20.513769},
                        {lat: 49.170237, lng: 20.513942},
                        {lat: 49.170048, lng: 20.514060},
                        {lat: 49.169426, lng: 20.514519},
                        {lat: 49.169473, lng: 20.514751},
                        {lat: 49.170452, lng: 20.514034},
                        {lat: 49.170712, lng: 20.514301},

                    ],
                    info: "",
                    zbgis: 'https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=orto&z=19&c=20.487405,49.133958&sc=n&dt=owners&it=point#/detail/kataster/parcela-c/833312/21533?zoom=false',
                    ...this.$options.styles
                }

            ]
        }
    },
    render: () => null,

}